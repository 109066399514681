import { ApplicationForm } from '../../components';
import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IJob } from '../../common';

export default function JobInfo() {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [curJob, setCurJob] = useState<IJob>();
    const { id: jobId } = useParams();
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        const getJob = async () => {
            try {
                const resp = await fetch(`/api/careers/${jobId}`);
                if (resp.ok) {
                    const job = await resp.json();
                    setCurJob(job);
                }
            } catch {
                setError('Job not found');
                navigate('/careers');
            }
        };
        void getJob();
    }, [jobId]);

    if (error)
        return (
            <div className="bg-black overflow-auto">
                <div
                    className="
            border-[0.5px] border-UL-green w-2/3 py-[10%] mx-auto
            md:border-[1px] md:w-1/2
            xl:border-2 xl:w-2/3"
                >
                    <div className="text-white text-center">
                        <h2 className="text-2xl font-bold py-5">
                            Job not found
                        </h2>
                        <div className="flex flex-row gap-2 justify-center">
                            <FaArrowLeft className="text-white my-auto" />
                            <Link to="/careers" className="text-white">
                                Back to careers
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );

    const title = curJob?.title;
    const location = curJob?.location;
    const overview = curJob?.overview;
    const responsibilities = curJob?.responsibilities;
    const qualifications = curJob?.qualifications;

    async function handleSubmit(formData: FormData) {
        setSubmitting(true);
        try {
            const response = await fetch('/api/application/submit', {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                setSubmitSuccess(true);
            } else {
                setSubmitError(true);
            }
        } catch (error) {
            console.error(error);
            setSubmitError(true);
        } finally {
            setSubmitting(false);
        }
    }

    if (submitSuccess) {
        return (
            <div className="bg-black overflow-auto">
                <div
                    className="
                    border-[0.5px] border-UL-green w-2/3 py-[10%] mx-auto bg-base-gray
                    md:border-[1px] md:w-1/2
                    xl:border-2 xl:w-2/3"
                >
                    <div className="text-white text-center">
                        <h2 className="text-2xl font-bold">
                            Application submitted successfully!
                        </h2>
                        <p className="mt-4">
                            Thank you for applying. We will review your
                            application shortly.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    function BulletPoint({ bullet }: { bullet: string }) {
        return <li className="text-white">{bullet}</li>;
    }

    function Qualification({ bullet }: { bullet: string }) {
        return <li className="text-white">{bullet}</li>;
    }

    return (
        <div className="bg-black overflow-auto">
            <div
                className="
            border-[0.5px] border-UL-green w-2/3 -ml-1 ob
            md:border-[1px] md:w-1/2
            xl:border-2 xl:w-1/3"
            >
                <h1
                    className="
                text-UL-green text-4xl p-5 
                md:text-6xl md:p-10
                xl:text-7xl xl:p-16 text-center"
                >
                    Careers
                </h1>
            </div>
            <div className="py-5"></div>
            <section className="pb-20 bg-base-gray w-[90%] mx-auto flex flex-col gap-5">
                <h2
                    className="
                text-UL-green text-2xl p-5 pb-2 
                md:text-3xl md:p-10 md:pb-3
                xl:text-4xl xl:p-16 xl:pb-2 text-center font-bold"
                >
                    {title}
                </h2>
                <h2
                    className="
                text-white text-lg pb-2
                md:text-xl md:pb-7
                xl:text-2xl xl:pb-10 text-center"
                >
                    {location}
                </h2>
                <div className="text-white font-light text-xl text-left md:text-xl w-3/4 mx-auto">
                    <h3 className="text-UL-green font-bold text-2xl">
                        Overview:
                    </h3>
                    <span className="pt-5">{overview}</span>
                </div>
                <div className="text-white font-light text-xl text-left md:text-xl w-3/4 mx-auto py-10">
                    <h3 className="text-UL-green font-bold text-2xl">
                        Responsibilities:
                    </h3>
                    <div className="mx-auto">
                        {responsibilities?.map((bullet, index) => {
                            return (
                                <ul
                                    className="flex flex-col list-disc ml-10 pt-3"
                                    key={index}
                                >
                                    <BulletPoint bullet={bullet} key={index} />
                                </ul>
                            );
                        })}
                    </div>
                </div>
                <div className="text-white font-light text-xl text-left md:text-xl w-3/4 mx-auto pb-10">
                    <h3 className="text-UL-green font-bold text-2xl">
                        Qualifications:
                    </h3>
                    <div className="mx-auto">
                        {qualifications?.map((bullet, index) => {
                            return (
                                <ul
                                    className="flex flex-col list-disc ml-10 pt-3"
                                    key={index}
                                >
                                    <Qualification bullet={bullet} />
                                </ul>
                            );
                        })}
                    </div>
                </div>
                <div className="bg-UL-green">
                    <div className="text-base-gray font-light text-xl text-left md:text-xl w-3/4 mx-auto py-10">
                        <h3 className="text-base-gray font-bold text-2xl">
                            About Us:
                        </h3>
                        <p className="pt-5">
                            Unlocked Labs is a non-profit organization that
                            partners with correctional educators and state
                            corrections departments to design technology
                            infrastructure to better deploy programs and track
                            outcomes, in order to understand efficacy and inform
                            best practices in the justice system.
                        </p>
                        <p className="pt-5">
                            Our team, which includes justice-impacted software
                            developers, technology industry experts, and
                            education and corrections professionals, works to:{' '}
                        </p>
                        <ul className="flex flex-col list-disc ml-7 pt-3 text-base-gray">
                            <li>
                                Build the open-source, public infrastructure is
                                needed to support correctional program outcomes.
                            </li>
                            <li>
                                Train, employ, and empower justice-impacted
                                individuals to obtain upwardly-mobile careers
                                upon release.
                            </li>
                            <li>
                                Serve as thought-leaders in the correctional
                                education and workforce development space to
                                promote human-centered outcomes and enable smart
                                decarceration.
                            </li>
                        </ul>
                    </div>
                    <div className="text-base-gray font-light text-xl text-left md:text-xl w-3/4 mx-auto">
                        <h3 className="text-base-gray font-bold text-2xl">
                            What we offer:
                        </h3>
                        <p className="pt-5">
                            Unlocked Labs&apos; theory of change is that, by
                            empowering individuals to take charge of their own
                            futures, we can both unlock their upward mobility
                            and create a fairer justice system. This applies to
                            our employees, too: it&apos;s important to us that
                            our team members can pursue the skills and projects
                            they want to build their long-term future. Not only
                            are we a mission-aligned organization, but
                            individual growth and satisfaction is paramount to
                            our organization&apos;s success. Have an idea that
                            you want to roll out? Want to change up one of our
                            processes or implement new software? Looking to
                            learn a new skill and apply it? Come up with a plan
                            and pitch it; we&apos;ll give feedback around
                            implementing it.{' '}
                        </p>
                        <p className="pt-5">
                            We also provide a wide range of benefits:{' '}
                        </p>
                        <ul className="flex flex-col list-disc ml-7 pt-3 pb-10 text-base-gray">
                            <li>Competitive salary</li>
                            <li>Group Health Insurance</li>
                            <li>Group Dental and Vision Insurance</li>
                            <li>
                                Team outings to local businesses of St. Louis
                            </li>
                            <li>
                                Free drinks and snacks at our coworking space
                            </li>
                            <li>
                                ~25 paid days off, not including holidays: 15
                                days of PTO, to be used at your discretion; a
                                team-wide “Wellness Week,” where the entire team
                                is off; and the week between Christmas Eve and
                                New Year&apos;s Day.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {!submitSuccess && (
                <section className="pb-20 bg-base-gray w-[90%] mx-auto flex flex-col gap-5">
                    <div className="text-UL-green font-bold text-3xl text-center">
                        Seem like a fit? Apply now:
                    </div>
                    <div className="w-3/4 mx-auto">
                        {curJob && (
                            <ApplicationForm
                                job={curJob}
                                handleSubmit={handleSubmit}
                                submitting={submitting}
                            />
                        )}
                    </div>
                    {submitError && (
                        <div className="text-red-500 text-center text-3xl w-1/2 mx-auto">
                            An error occured while trying to submit your
                            application. Please try again later. <br />
                            If the issue persists, please{' '}
                            <Link
                                to="/contact"
                                className="hover:text-UL-green font-bold"
                            >
                                contact us here.
                            </Link>
                        </div>
                    )}
                </section>
            )}
        </div>
    );
}
